body {
  background: #FBFBFB !important;
  color: #333 !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Modal hack to keep it visible above map */
.baseStyles__BaseModalBackground-sc-12bzkqq-0 {
  z-index: 999999 !important;
}

.rc-pagination-prev, .rc-pagination-next, .rc-pagination-item {
  background: none !important;
  border-color: #7c7c7c !important;
  outline: 0;
}

.rc-pagination-prev a, .rc-pagination-next a, .rc-pagination-item a {
  color: #eee !important;
}

.rc-pagination-disabled a {
  color: #666 !important;
}

.rc-pagination-item-active {
  background: #5c5c5c !important;
}
